import {
	getLoginTokenApi,
	getStoreApi,
	getUserByUsernameApi,
	logoutApi,
} from '../../apiCalls'
import appConfig from '../../config'
import {
	delIntersessionInfo,
	setIntersessionAppLoginData,
} from '../../libs/intersession'
import { AppThunk, AppThunkPromise, Id } from '../../model/model'
import { User } from '../../model/user'
import settingsActions from '../settings/actions'
import { selectStoreId } from '../settings/selectors'
import { slice } from './slice'

const getToken =
	(code: string): AppThunkPromise =>
	dispatch =>
		getLoginTokenApi(code).then(decodedRes => {
			if (!decodedRes) {
				return
			}

			dispatch(slice.actions.setAppToken(decodedRes.token))
			setIntersessionAppLoginData(decodedRes.token)
			// TODO: request token refresh
			// setTimeout(() => {
			// 	console.log('FAKE refresh token')
			// }, decodedToken.exp * 1000 - FIVE_MINUTES - Date.now())
		})

const fetchUser =
	(username: string): AppThunkPromise<User | void> =>
	(dispatch, getState) =>
		getUserByUsernameApi(username).then(userRes => {
			if (!userRes) {
				return
			}
			const user = userRes
			dispatch(slice.actions._setUser(user))
			if (!user.stores || !user.stores.length) {
				return user
			}

			const state = getState()
			const selectedStore = selectStoreId(state)
			const inStoreCount = user.stores.length

			let storeId: string | undefined

			if (inStoreCount === 1 && !selectedStore) {
				storeId = user.stores[0].store._id
			} else if (inStoreCount > 1 && selectedStore.length > 0) {
				const storeCooperation = user.stores.find(
					storeCooperation => storeCooperation.store._id === selectedStore,
				)
				if (storeCooperation) {
					storeId = storeCooperation.store._id
				}
			}

			if (storeId !== undefined) {
				dispatch(settingsActions.setStoreId(storeId))
			}

			return user
		})

const logout = (): AppThunk => dispatch => {
	return logoutApi()
		.catch(() => {
			// if token is expired the logout will fail with 401, just ignore it
		})
		.finally(() => {
			delIntersessionInfo()
			dispatch(slice.actions.resetState())
			window.location.href = appConfig.logout
		})
}

const fetchStore =
	(storeId: Id): AppThunk =>
	(dispatch, getState) => {
		getStoreApi(storeId).then(storeRes => {
			if (!storeRes) {
				return
			}

			const user = getState().auth.user

			if (!user) {
				return
			}

			const userStores = user?.stores.filter(s => s.store._id !== storeId) || []
			const storeToUpdate = user?.stores.filter(s => s.store._id === storeId)[0]

			if (!storeToUpdate) {
				return
			}

			const updateStores = [
				...userStores,
				{ ...storeToUpdate, store: storeRes },
			]

			const updatedUser = { ...user, stores: updateStores }
			dispatch(authActions._setUser(updatedUser))
		})
	}

const authActions = {
	...slice.actions,
	getToken,
	fetchUser,
	logout,
	fetchStore,
}

export default authActions
