import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import authActions from '../features/auth/actions'
import { selectUsername } from '../features/auth/selectors'
import { selectStoreId } from '../features/settings/selectors'
import {
	connectToSocketKeepAlive,
	disconnectFromSocketKeepAlive,
} from '../socket/keepAlive/keepAliveSocket'
import { useSocketRoom } from './useSocket'

export const useKeepAliveUser = () => {
	const username = useSelector(selectUsername)

	useEffect(() => {
		connectToSocketKeepAlive({
			username,
		})

		return () => disconnectFromSocketKeepAlive()
	}, [username])
}

export const useUpdateStoreRoom = () => {
	const storeId = useSelector(selectStoreId)
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(authActions.fetchStore(storeId))
	}, [])

	useSocketRoom()
}
